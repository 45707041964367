import { render, staticRenderFns } from "./Reviews.vue?vue&type=template&id=e0a4bbd2"
import script from "./Reviews.vue?vue&type=script&lang=js"
export * from "./Reviews.vue?vue&type=script&lang=js"
import style0 from "./Reviews.vue?vue&type=style&index=0&id=e0a4bbd2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QInfiniteScroll,QTabs,QTab,QTabPanels,QTabPanel,QColor,QItem,QImg,QRating});
