<template>
  <q-list class="account-review-list">
    <q-item
      v-for="customerReview in customerReviews"
      :key="customerReview.seoName"
      class="item-start"
    >
      <q-item-section avatar>
        <router-link
          :to="{
            name: 'Detail',
            params: {
              seoName: customerReview.seoName,
            },
          }"
        >
          <q-img
            :src="customerReview.profileImage | defaultProductImage"
            spinner-color="primary"
            :ratio="1"
            contain
            width="100px"
          />
        </router-link>
      </q-item-section>

      <q-item-section>
        <q-item-label class="product-title ellipsis-2-lines">
          <router-link
            :to="{
              name: 'Detail',
              params: {
                seoName: customerReview.seoName,
              },
            }"
            v-html="customerReview.productName"
          />
        </q-item-label>
        <!-- <q-item-label class="ellipsis-2-lines">
          {{ customerReview.reviewTitle }}
        </q-item-label> -->
        <q-item-label class="product-review" v-if="customerReview.reviewID">
          <q-rating
            :value="!customerReview.ratings ? 0 : customerReview.ratings"
            max="5"
            color="orange"
            icon="star_border"
            icon-selected="star"
            icon-half="star_half"
            no-dimming
            readonly
          />
          <q-item-label>
            {{ customerReview.reviewDate | formatDate }}
          </q-item-label>
        </q-item-label>
        <q-item-label caption>
          <template v-if="customerReview.reviewID">
            <p class="product-desc">
              {{ customerReview.reviewDescription }}
            </p>
            <q-item-section v-if="reviewType === 0">
              <q-item-label>
                <q-btn
                  @click="customerReviewDialog(customerReview)"
                  unelevated
                  color="grey-2"
                  text-color="primary"
                  class="q-my-sm q-mr-sm"
                >
                  <SvgIcon size="12px" icon="edit" class="q-mr-sm" /> Edit
                </q-btn>
                <Trash :review="customerReview" />
              </q-item-label>
            </q-item-section>
          </template>
          <template v-else>
            <p>
              <q-btn
                unelevated
                no-caps
                color="white"
                text-color="tertiary"
                label="Write a review"
                padding="none"
                class="text-underline product-review-link no-hover"
                @click="customerReviewDialog(customerReview)"
              />
            </p>
          </template>
        </q-item-label>
      </q-item-section>

      <q-item-section side v-if="customerReview.reviewID && reviewType == 0">
        <q-item-label class="text-center status">
          <SvgIcon size="2.5rem" icon="review-pending" /><br />Pending
        </q-item-label>
      </q-item-section>

      <q-item-section side v-if="reviewType === 1">
        <q-item-label class="text-center user-review-action-btn">
          <q-btn
            @click="customerReviewDialog(customerReview)"
            unelevated
            color="grey-2"
            text-color="primary"
            class="q-my-sm full-width"
          >
            <SvgIcon size="12px" icon="edit" class="q-mr-sm" /> Edit
          </q-btn>
          <Trash :review="customerReview" :btnClass="'full-width'" />
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
import Trash from './Trash'

export default {
  name: 'CustomerReviews',
  components: {
    Trash,
  },
  props: {
    customerReviews: {
      type: Object,
      default: () => {
        return {}
      },
    },
    reviewType: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    customerReviewDialog(customerReview) {
      const editReview = customerReview.reviewID ? true : false
      this.changeDialogState({
        dialog: 'writeReview',
        properties: {
          customerReview: { ...customerReview, editReview: editReview },
        },
        refresh: false,
        val: true,
      })
    },
  },
  data() {
    return {
      customerReview: {},
    }
  },
}
</script>

<style lang="scss">
.user-review-action-btn {
  @media (max-width: 599px) {
    width: calc(70% + 10px);
    display: flex;
    > button {
      font-size: 12px;
      & + button {
        margin-left: 2px !important;
      }
    }
  }
}
</style>
