<template>
  <q-btn
    @click="deleteCustomerReview"
    unelevated
    color="grey-2"
    text-color="red-12"
    class="q-my-sm"
    :loading="deleteting"
    :class="btnClass"
  >
    <SvgIcon size="12px" icon="trash" class="q-mr-sm" /> Delete
  </q-btn>
</template>
<script>
export default {
  name: 'Trash',
  props: {
    review: {
      type: Object,
      default: () => {
        return {}
      },
    },
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      deleteting: false,
    }
  },
  methods: {
    deleteCustomerReview() {
      this.ftxConfirm('Are you sure you want to delete item review?').onOk(
        () => {
          this.deleteting = true
          this.$store
            .dispatch(
              'customerReview/deleteCustomerReview',
              this.review.reviewID
            )
            .then((response) => {
              if (response) {
                this.$root.$emit(
                  'removePublishedReviewsByProductID',
                  this.review
                )
                this.showSuccess('Your Review Removed Successfully.')
              }
            })
            .finally(() => {
              this.deleteting = false
            })
        }
      )
    },
  },
}
</script>
