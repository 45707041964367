<template>
  <q-page v-if="businessConfigurations?.enableReviews" class="accountPages">
    <div class="container">
      <div class="page-title hide-in-mobile">
        <div class="page-breadcrumb">
          <a
            href="javascript:void(0)"
            @click="applyBack()"
            class="no-underline"
          >
            <SvgIcon icon="back-arrow-line" />Back
          </a>
        </div>
        <h2>Item Reviews</h2>
        <p>
          See your previous reviews and pending reviews on products you’ve
          purchased.
        </p>
      </div>
      <div class="page-content">
        <!-- Customer Review Tabs -->
        <div class="accountTabs">
          <q-infinite-scroll
            @load="fetchNext"
            ref="infiniteScroll"
            class="accoutOrderItems"
            :offset="1000"
          >
            <q-tabs
              v-model="tab"
              class="text-grey"
              active-color="primary"
              indicator-color="primary"
              align="justify"
              @input="refreshListing"
            >
              <q-tab
                v-for="(reviewTab, index) in reviewTabs"
                :key="'tab_' + reviewTab.type"
                :name="index"
                :label="reviewTab.title"
              />
            </q-tabs>
            <q-tab-panels v-model="tab" animated>
              <q-tab-panel
                v-for="(reviewTab, index) in reviewTabs"
                :key="`tab_panel_${reviewTab.type}`"
                :name="index"
                style="padding-top: 20px"
              >
                <CustomerReviews
                  :customerReviews="customerReviews"
                  :reviewType="reviewTab.type"
                />
              </q-tab-panel>
            </q-tab-panels>
            <template v-slot:loading>
              <ReviewSkeleton v-show="showSkeletonLoader" />
              <LoadMoreSpinner v-show="!showSkeletonLoader" />
            </template>
          </q-infinite-scroll>
          <EmptyComponent
            v-if="!loading && Object.keys(customerReviews).length == 0"
            image="images/empty-review.png"
          >
            <p>No Reviews Found</p>
          </EmptyComponent>
        </div>
        <!-- Customer Review Tabs -->
      </div>
    </div>
    <WriteReview />
  </q-page>
</template>

<script>
import { convertArrayToObject } from 'src/utils'
import CustomerReviews from 'components/customer-review/CustomerReviews'
import ReviewSkeleton from 'components/customer-review/ReviewSkeleton'

export default {
  name: 'PageReviews',
  components: {
    CustomerReviews,
    ReviewSkeleton,
    WriteReview: () => import('components/detail/WriteReview'),
  },
  meta() {
    return {
      title: 'Reviews',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      tab: 'pending',
      reviewTabs: {
        pending: {
          title: 'Pending',
          type: 0,
        },
        published: {
          title: 'Published',
          type: 1,
        },
      },
      pageSize: 10,
      showSkeletonLoader: true,
      loading: true,
      customerReviews: {},
    }
  },
  computed: {
    objRequest() {
      return {
        status: this.tab,
        PageSize: this.pageSize,
      }
    },
  },
  methods: {
    fetchNext(index, done) {
      let params = {
        Page: index,
        ...this.objRequest,
      }

      if (index == 1) {
        this.showSkeletonLoader = true
        this.customerReviews = {}
      }

      this.loading = true

      this.$store
        .dispatch('customerReview/getCustomerReviews', params)
        .then((response) => {
          if (response?.success && response.data) {
            this.customerReviews = Object.assign(
              {},
              this.customerReviews,
              convertArrayToObject(response.data.items, 'productID')
            )
            done(!response.data.hasNext)
          } else {
            this.customerReviews = {}
            done(true)
          }

          this.loading = false
          this.showSkeletonLoader = false
        })
    },
    refreshListing(value) {
      this.$router.replace({ query: { tab: value } }).catch((err) => {})

      this.$refs.infiniteScroll.stop()
      this.$refs.infiniteScroll.reset()
      this.$refs.infiniteScroll.resume()
      this.$refs.infiniteScroll.trigger()
    },
    getReviews(tab, data) {
      if (Object.values(this.customerReviews).length > 0) {
        this.customerReviews[data.productID] = data
        const reviews = Object.values(this.customerReviews)
        if (tab == 'pending') {
          this.customerReviews = convertArrayToObject(
            reviews.filter((x) => x.status == 0),
            'productID'
          )
        } else if (tab == 'published') {
          this.customerReviews = convertArrayToObject(
            reviews.filter((x) => x.status == 2),
            'productID'
          )
        }
      }
    },
  },
  created() {
    if (this.$route.query?.tab && this.reviewTabs[this.$route.query.tab])
      this.tab = this.$route.query.tab
  },
  mounted() {
    // if (!this.businessConfigurations?.enableReviews)
    //   this.$router
    //     .push({
    //       name: 'Home',
    //     })
    //     .catch(() => {})

    this.$root.$on('removePublishedReviewsByProductID', (response) => {
      if (response.productID)
        this.$delete(this.customerReviews, response.productID)
    })

    this.$root.$on('replacePendingReviewByProductID', (data) => {
      const copyOfCustomerReviews = JSON.parse(
        JSON.stringify(this.customerReviews)
      )
      if (!this.businessConfigurations.enableReviewVerification)
        delete copyOfCustomerReviews[data.productID]
      else if (
        this.businessConfigurations.enableReviewVerification &&
        copyOfCustomerReviews.hasOwnProperty(data.productID)
      )
        copyOfCustomerReviews[data.productID] = data
      this.customerReviews = copyOfCustomerReviews
    })

    this.$root.$on('replacePublishedReviewWhenUpdate', (data) => {
      this.getReviews(this.tab, data)
    })

    this.$root.$on('editedReviewResponse', (data) => {
      this.getReviews(this.tab, data)
    })
  },
}
</script>
<style lang="scss">
.accountTabs {
  .q-tabs__content {
    border: 1px solid #cccccc;
    .q-tab {
      &.q-tab--active {
        .q-tab__label {
          color: $primary;
          color: var(--q-color-primary) !important;
          font-weight: bold;
        }
      }
      @media (min-width: 1681px) {
        min-height: 60px;
      }
    }
    .q-tab__label {
      font-size: 15px;
      color: #888888;
      @media (min-width: 1681px) {
        font-size: 18px;
      }
    }
    .q-tab__indicator {
      height: 4px;
    }
  }
  .q-tab-panel {
    padding-left: 0;
    padding-right: 0;
  }
}
.account-review-list {
  .q-item {
    padding: 30px 0;
    border-top: 1px dashed rgba(112, 112, 112, 0.2);
    @media (max-width: 599px) {
      flex-wrap: wrap;
      align-items: flex-start;
      .q-item__section--side {
        flex: 1 0 100%;
        width: auto;
      }
      .q-item__section--avatar {
        flex: 1 0 30%;
        width: auto;
      }
      .q-item__section--main {
        flex: 1 0 70%;
      }
    }
    &:first-child {
      border: none;
    }
    .q-item__section--side {
      width: 120px;
    }
    .q-item__section--avatar {
      min-width: 120px;
      > a {
        width: 100%;
      }
      @media (max-width: 599px) {
        min-width: 60px;
        .q-img {
          width: 100% !important;
        }
      }
      .q-img {
        border: 1px solid #eeeeee;
        .q-img__image {
          margin: 5px;
        }
      }
    }
    .product-title {
      font-size: 16px;
      @media (min-width: 1681px) {
        font-size: 20px;
      }
      a {
        color: $tertiary;
        text-decoration: none;
        &:hover {
          color: $primary;
          color: var(--q-color-primary) !important;
        }
      }
    }
    .product-desc {
      font-size: 16px;
      line-height: 22px;
      word-break: break-all;
      @media (max-width: 599px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
    .product-review-link {
      display: inline-block;
      margin-top: 10px;
      font-size: 14px;
      color: $tertiary;
      text-decoration: underline;
      @media (min-width: 1681px) {
        font-size: 20px;
      }
      &:hover {
        color: $primary;
        color: var(--q-color-primary) !important;
      }
    }
  }
  .status {
    font-size: 16px;
    color: #cccccc;
  }
  .product-review {
    padding: 2px 0;
    .q-rating {
      font-size: 1.4em;
    }
    .q-rating__icon {
      color: #707070 !important;
      text-shadow: none;
    }
    .text-orange {
      color: #ffbd09 !important;
      text-shadow: none;
    }
    .q-item__label {
      margin-left: 10px;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      color: #393939;
      opacity: 0.5;
    }
  }
}
</style>
